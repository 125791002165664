import { TFunction } from 'i18next';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';
import ForumIcon from '@mui/icons-material/Forum';
import SettingsIcon from '@mui/icons-material/Settings';
import LayersIcon from '@mui/icons-material/Layers';
import ViewListIcon from '@mui/icons-material/ViewList';
import NotesIcon from '@mui/icons-material/Notes';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CategoryIcon from '@mui/icons-material/Category';
import QueueIcon from '@mui/icons-material/Queue';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import PreviewIcon from '@mui/icons-material/Preview';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import GroupsIcon from '@mui/icons-material/Groups';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import ImageIcon from '@mui/icons-material/Image';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import InsightsIcon from '@mui/icons-material/Insights';
import HubIcon from '@mui/icons-material/Hub';
import KeyIcon from '@mui/icons-material/Key';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import WarningIcon from '@mui/icons-material/Warning';
import { userService } from 'common/services/user.service';
import { RetailerType } from './entities';
import { RoutesAvailability } from './routes-availability';

export interface NavItem {
  title: string;
  path?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: OverridableComponent<SvgIconTypeMap<any, 'svg'>> & {
    muiName: string;
  };
  nested?: Array<NavItem>;
  isAvailable: boolean;
  demoBlur: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getMainNavConfig = (t: TFunction): Array<NavItem> => [
  {
    title: 'Overview',
    path: '/',
    icon: userService.ensureRetailer(RetailerType.Walmart) ? LayersIcon : ViewListIcon,
    isAvailable: RoutesAvailability.Overview() && userService.ensureRetailer(RetailerType.Walmart),
    demoBlur: true,
  },
  {
    title: 'Core Actions',
    isAvailable: true,
    demoBlur: false,
    icon: HubIcon,
    nested: [
      {
        title: 'Overview',
        path: '/overview',
        icon: QueryStatsIcon,
        isAvailable: RoutesAvailability.OverviewPage(),
        demoBlur: false,
      },
      {
        title: 'Products',
        path: '/',
        icon: userService.ensureRetailer(RetailerType.Walmart) ? LayersIcon : ViewListIcon,
        isAvailable: RoutesAvailability.Overview() && !userService.ensureRetailer(RetailerType.Walmart),
        demoBlur: true,
      },
      {
        title: 'Products',
        path: '/content',
        icon: ViewListIcon,
        isAvailable: RoutesAvailability.ContentManagement(),
        demoBlur: true,
      },
      {
        title: 'Categories',
        path: '/categories-settings',
        icon: CategoryIcon,
        isAvailable: RoutesAvailability.CategoriesSettings(),
        demoBlur: true,
      },
      {
        title: 'Keywords Tool',
        path: '/keywords-tool',
        icon: KeyIcon,
        isAvailable: RoutesAvailability.KeywordsTool(),
        demoBlur: true,
      },

      {
        title: 'Add XLSX',
        path: '/add-xlsx',
        icon: AddCircleOutlineIcon,
        isAvailable: RoutesAvailability.AddXLSX(),
        demoBlur: true,
      },
      {
        title: 'Add Product IDs',
        path: '/add-tcins',
        icon: AddCircleOutlineIcon,
        isAvailable: RoutesAvailability.AddTCINs(),
        demoBlur: true,
      },
      {
        title: 'Add Product IDs',
        path: '/add-asins',
        icon: AddCircleOutlineIcon,
        isAvailable: RoutesAvailability.AddASINs(),
        demoBlur: true,
      },
      {
        title: 'Add Product IDs',
        path: '/add-skus',
        icon: AddCircleOutlineIcon,
        isAvailable: RoutesAvailability.AddSKUs(),
        demoBlur: true,
      },
      {
        title: userService.ensureRetailer(RetailerType.Walmart) ? 'Walmart Change Tracking' : 'Target Change Tracking',
        path: '/change-tracking',
        icon: ManageHistoryIcon,
        isAvailable: RoutesAvailability.ChangeTracking(),
        demoBlur: false,
      },
      {
        title: 'PDP Generation',
        path: '/pdp-gen',
        icon: AutoFixHighIcon,
        isAvailable: RoutesAvailability.PDPGeneration(),
        demoBlur: true,
      },
      {
        title: 'Quick Start',
        path: '/start',
        icon: PreviewIcon,
        isAvailable: true,
        demoBlur: true,
      },
    ],
  },

  {
    title: 'Insights',
    isAvailable: true,
    demoBlur: false,
    icon: InsightsIcon,
    nested: [
      {
        title: 'AI',
        icon: AutoAwesomeIcon,
        isAvailable: true,
        demoBlur: false,
        nested: [
          {
            title: 'Queue',
            path: '/ai-queue',
            icon: QueueIcon,
            isAvailable: RoutesAvailability.AIQueue(),
            demoBlur: false,
          },
          {
            title: 'Instructions',
            path: '/ai-instructions',
            icon: AltRouteIcon,
            isAvailable: RoutesAvailability.AIInstructions(),
            demoBlur: false,
          },
          {
            title: 'Swatch Creator',
            path: '/swatch-creator',
            icon: ImageIcon,
            isAvailable: RoutesAvailability.SwatchCreator(),
            demoBlur: false,
          },
          {
            title: 'Warning Settings',
            path: '/warning-rules',
            icon: WarningIcon,
            isAvailable: RoutesAvailability.WarningRules(),
            demoBlur: false,
          },
          {
            title: 'Retailer AI Settings',
            path: '/ai-settings',
            icon: SettingsSuggestIcon,
            isAvailable: RoutesAvailability.AISettings(),
            demoBlur: false,
          },
        ],
      },
      {
        title: 'Performance',
        icon: ShowChartIcon,
        isAvailable: RoutesAvailability.Performance(),
        demoBlur: false,
        nested: [
          {
            title: 'Leaderboard',
            path: '/leaderboard',
            icon: LeaderboardIcon,
            isAvailable: RoutesAvailability.Leaderboard(),
            demoBlur: false,
          },
          {
            title: 'AI',
            icon: AutoAwesomeIcon,
            isAvailable: true,
            demoBlur: false,
            nested: [
              {
                title: 'Benchmark',
                path: '/ai-benchmark',
                icon: InsertChartIcon,
                isAvailable: RoutesAvailability.AIBenchmark(),
                demoBlur: false,
              },
              {
                title: 'Benchmark Stats ',
                path: '/ai-benchmark-stats',
                icon: DonutLargeIcon,
                isAvailable: RoutesAvailability.AIBenchmarkStats(),
                demoBlur: false,
              },
            ],
          },
          {
            title: 'Compare',
            icon: GroupsIcon,
            isAvailable: true,
            demoBlur: false,
            nested: [
              {
                title: 'Benchmark',
                path: '/compare-benchmark',
                icon: InsertChartIcon,
                isAvailable: RoutesAvailability.CompareBenchmark(),
                demoBlur: false,
              },
              {
                title: 'Benchmark Stats ',
                path: '/compare-benchmark-stats',
                icon: DonutLargeIcon,
                isAvailable: RoutesAvailability.CompareBenchmarkStats(),
                demoBlur: false,
              },
            ],
          },
        ],
      },
      {
        title: 'Comments',
        path: '/comments',
        icon: NotesIcon,
        isAvailable: RoutesAvailability.Comments(),
        demoBlur: true,
      },
    ],
  },

  {
    title: 'Support Tools',
    isAvailable: true,
    demoBlur: false,
    icon: SupportAgentIcon,
    nested: [
      {
        title: 'Support',
        path: '/support',
        icon: ForumIcon,
        isAvailable: RoutesAvailability.Support(),
        demoBlur: true,
      },
      {
        title: 'Settings',
        path: '/settings/team',
        icon: SettingsIcon,
        isAvailable: RoutesAvailability.Settings(),
        demoBlur: true,
      },
    ],
  },
];
