import { useQueryClient } from '@tanstack/react-query';
import { RetailerType } from 'common/constants/entities';
import {
  useGenerateAIForTCIN,
  useGenerateAIForWPIDSingleAttributeMutation,
} from 'common/hooks/api/mutations/use-attributes-mutation';
import { AttributesQueryKey } from 'common/hooks/api/queries/use-attributes-query';
import { ContentQueryKey } from 'common/hooks/api/queries/use-content-query';
import { userService } from 'common/services/user.service';
import toast from 'react-hot-toast';

interface Props {
  issuesOnly?: boolean;
  pdpGen?: boolean;
  seoGen?: boolean;
  pid: string;
}

export function useGeneratePDP({ issuesOnly, pdpGen, seoGen, pid }: Props) {
  const queryClient = useQueryClient();

  const { mutate: mutateGenerateRetailerAI, isLoading: isRetailerAIGenerationLoading } = useGenerateAIForTCIN();
  const { mutate: mutateGenerateWalmartAI, isLoading: isWalmartAIGenerationLoading } =
    useGenerateAIForWPIDSingleAttributeMutation();

  const handleGenerateAI = () => {
    const handleSuccess = () => {
      toast.success('Templates generated succesfully');
      queryClient.invalidateQueries([ContentQueryKey.Content, pid]);
      queryClient.invalidateQueries([AttributesQueryKey.Keywords, pid]);
    };

    const commonParams = {
      optimizeAttributes: true,
      generateAttributes: false,
      overwriteOptimizedAttributes: true,
      ...(userService.ensureRetailer(RetailerType.Walmart) ? { pdpAttributesWithIssuesOnly: issuesOnly } : {}),
    };

    switch (userService.getAPIRetailer()) {
      case RetailerType.POC:
      case RetailerType.Target:
        mutateGenerateRetailerAI(
          {
            ...commonParams,
            tcin: pid,
            pdpSeoOptimization: seoGen,
            pdpGeneration: pdpGen,
          },
          { onSuccess: handleSuccess }
        );
        break;
      case RetailerType.Walmart:
      case RetailerType.Flywheel:
        mutateGenerateWalmartAI({ ...commonParams, wpid: pid }, { onSuccess: handleSuccess });
        break;
      default:
        break;
    }
  };

  return { handleGenerateAI, isLoading: isRetailerAIGenerationLoading || isWalmartAIGenerationLoading };
}
