import { Box, Tab, Tabs } from '@mui/material';
import { Styles } from 'common/types/styles';
import { ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import { Modal } from 'common/ui/containers/modal';
import { userService } from 'common/services/user.service';
import { RetailerType } from 'common/constants/entities';
import { usePDPAttributeHistoryQuery } from 'common/hooks/api/queries/use-content-query';
import { ProductAITabValue } from './constants';
import { PdpTemplateContent } from './pdp-template-content';
import { PdpHistoryContent } from './pdp-history-content';
import { PDPKeywords } from './pdp-keywords';

const styles: Styles = {
  container: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  tabs: { width: '100%', backgroundColor: '#FAFAFA' },
};

interface Props {
  pid: string;
  open: boolean;
  onClose: () => void;
}

export function PdpAIModal({ pid, open, onClose }: Props): ReactElement {
  const isL3 = userService.ensureLevel('L3');

  const defaultSelectedTab = !isL3 ? ProductAITabValue.Template : ProductAITabValue.History;

  const { data: historyData } = usePDPAttributeHistoryQuery(
    {
      pid,
    },
    open
  );

  const [selectedTab, setSelectedTab] = useState<ProductAITabValue>(defaultSelectedTab);

  const handleTabChange = (_: SyntheticEvent<Element, Event>, value: ProductAITabValue) => {
    setSelectedTab(value);
  };

  useEffect(() => {
    if (open) return;
    setSelectedTab(defaultSelectedTab);
  }, [defaultSelectedTab, open]);

  return (
    <Modal open={open} onClose={onClose} maxWidth="xl" title="PDP AI Template Settings">
      <Box sx={styles.container}>
        <Tabs onChange={handleTabChange} value={selectedTab} sx={styles.tabs} centered>
          {!isL3 && <Tab value={ProductAITabValue.Template} tabIndex={ProductAITabValue.Template} label="Template" />}

          {!isL3 && <Tab value={ProductAITabValue.Prompt} tabIndex={ProductAITabValue.Prompt} label="Prompt" />}

          {userService.ensureRetailers([RetailerType.Target, RetailerType.Amazon, RetailerType.Autozone]) && (
            <Tab value={ProductAITabValue.Keywords} tabIndex={ProductAITabValue.Keywords} label="Keywords" />
          )}

          {historyData?.history && (
            <Tab value={ProductAITabValue.History} tabIndex={ProductAITabValue.History} label="History" />
          )}
        </Tabs>

        {!isL3 && [ProductAITabValue.Prompt, ProductAITabValue.Template].includes(selectedTab) && (
          <PdpTemplateContent pid={pid} selectedTab={selectedTab} />
        )}

        {selectedTab === ProductAITabValue.Keywords && <PDPKeywords pid={pid} />}

        {selectedTab === ProductAITabValue.History && <PdpHistoryContent pdpAttributes={historyData?.history} />}
      </Box>
    </Modal>
  );
}
