import { WarningRules } from 'common/services/api/attributes/attributes-api.types';

export interface TemplateRule {
  id?: string;
  acceptable_units?: string;
  acceptable_values?: string;
  attribute?: string;
  attribute_type?: string;
  children_attributes?: string;
  closed_list?: string;
  conditional_requirement?: string;
  definition?: string;
  display_name?: string;
  example_values?: string;
  warning_rules?: WarningRules;
  key?: string;
  l2_header?: string;
  max_char?: number;
  min_char?: number;
  min_word_count?: number;
  minimum_required_entries?: number;
  multi_select?: string;
  operations_direction?: string;
  parent_attribute_name?: string;
  precision?: string;
  product_type?: string;
  recommended_no_of_entries?: string;
  recommended_word_count?: number;
  requirement_level?: string;
  restrict_viewing_by?: string;
  schema_key?: string;
  validation_instructions?: string;
  data_type?: string;
  value?: string;
  weight?: { weight: number };
  settings?: TemplateRuleSettings;
  max_selections?: number;
  ref?: string;
}

export interface TemplateRuleSettings {
  caution?: boolean;
  compliance?: boolean;
  disabled?: boolean;
  in_scope?: boolean;
  invalid?: boolean;
  invalid_core_content?: boolean;
  flagged?: boolean;
  flag_reason?: string;
  default_value?: string;
  ai_special_instructions?: string;
  ai_opt_out?: boolean;
  ai_vision_enabled?: boolean;
  ai_override?: boolean;
  review_attribute?: boolean;
  propagate_ai_special_inst?: boolean;
  auto_ai_validation?: boolean;
  ai_validation_model_name?: string;
  model_name?: string;
}

export interface ContentCategory {
  category_id: number;
  content: {
    items: Array<TemplateRule>;
  };
  created_at: string;
  description: string;
  id: string;
  name: string;
  tags: Array<string>;
  updated_at: string;
}

export interface Vendor {
  id: number;
  name: string;
}

export interface User {
  id: number;
  auth0_id: string;
  email: string;
  name: string;
  picture: string;
  roles?: Array<string>;
  hourlyRate: number;
  vendors?: Array<Vendor>;
  flags: Record<UserFlag, 0 | 1>;
}

export interface UserFilter {
  page: number;
  limit: number;
  search?: string;
  vendorsIDs?: Array<number>;
}

export interface UserUpdateBody {
  rolesIDs: Array<number>;
  vendorsIDs?: Array<number>;
  hourlyRate?: number;
  flags?: Record<UserFlag, 1 | 0>;
}

export interface Role {
  id: number;
  name: string;
}

export interface ExtendedAuthUser extends User {
  assignments: number;
  roles: Array<string>;
}

export interface Auth0Role {
  auth0_id: string;
  id: number;
  name: string;
}

export interface ReviewSession {
  key: string;
  lastCheckpoint: string;
  startedAt: string;
}

export interface SessionData {
  duration: string;
  id: number;
  startedAt: string;
}

export interface TotalSessionStats {
  date?: string;
  approvedItems: number;
  pauseTimeMS: number;
  pausedTime: string;
  skippedItems: number;
  spentTime: string;
  spentTimeMS: number;
  totalItems: number;
}

export interface SessionStats {
  totalStats: TotalSessionStats;
  dailyStats: TotalSessionStats;
}

export interface SearchedCategory {
  id: number;
  external_id: number;
  name: string;
  productsCount: number;
}

export interface VisionResult {
  confidence_level: string;
  value: string;
}

export interface TemplateEntry {
  name: string;
  description: string;
  tags: Array<string>;
  content: {
    items: Array<TemplateRule>;
  };
  created_at: string;
  id: string;
}

export interface ImagesAnalysisResult {
  image_analysis_procedure: string;
  vision_result: Record<string, string>;
}

export interface GeneralWarningRules {
  image_analysis_procedure: string;
  vision_result: Record<string, string>;
}

export interface ContentEntry {
  bullet_point_value: string;
  error_message: string;
  category_id: number;
  created_at: string;
  name: string;
  title: string;
  product_details: string;
  product_url: string;
  product_id: string;
  pid: string;
  status: string;
  tags: Record<string, string>;
  brand_analysis_id: string;
  brand_analysis: Record<string, string>;
  images_analysis: { result: ImagesAnalysisResult };
  image_url: string;
  valid_attributes_required: number;
  valid_attributes_total: number;
  attributes_required: number;
  attributes_total: number;
  quality_score: number;
  attributes_stats?: { total: number; in_scope: number; filled: number };
  total_predicted_weight: number;
  user?: User;
  approvedBy?: User;
  approved_by?: User;
  skip_level: number;
  commentsCount?: number;
  stock_keeping_unit?: string;
  sku?: string;
  seo_keywords_time_range?: string;
  warnings?: {
    title?: Array<string>;
    short_description?: Array<string>;
    long_description?: Array<string>;
  };
}

export interface TargetContentEntry {
  brand: Array<string>;
  class_id: string;
  class_name: string;
  color: string;
  description: string;
  feature_bullets: string;
  features: string;
  shipping: { dimensions: string; weight: string };
  specifications: string;
  department_id: string;
  department_name: string;
  guest_facing_brand: string;
  images: Array<{ link: string }>;
  item_status: string;
  item_status_code: string;
  item_type: string;
  item_type_category: string;
  item_type_name: string;
  launch_date: string;
  manufacturer: string;
  merch_type_attributes: Array<{
    id: string;
    name: string;
    value_id: string;
    value: string;
    unit_of_measure: string;
  }>;
  merch_type_id: string;
  merch_type_name: string;
  parent_sku: string;
  primary_fulfillment_location_code: string;
  product_group_id: string;
  product_group_name: string;
  product_long_description: string;
  product_subtype_id: string;
  product_subtype_name: string;
  product_title: string;
  published: string;
  relationship_type: string;
  release_date: string;
  scheduled_launch_date: string;
  size: string;
  street_date: string;
  tcin: string;
  upcs: string;
}

export interface ContentImageVision {
  text: string[];
  labels: string[];
}

export interface PdpRequestMessage {
  content: string;
  role: string;
}

export interface PdpAttribute {
  optimization_process: string;
  price: number;
  generated_at: string;
  model: string;
  ai_platform: string;
  response: { content: string };
  request: { messages: Array<PdpRequestMessage> };
  date?: string;
}

export interface PdpAttributeData {
  [date: string]: PdpAttribute[];
}

export interface OptimizationsFields {
  optimized: {
    description: { value: string; revised_value?: string };
    title: { value: string; revised_value?: string };
    features: { value: string; revised_value?: string };
  };
  seo_optimized: {
    description: { value: string };
    title: { value: string };
    features: { value: string };
    keywords?: { value: string };
  };
}

export interface IssueFields {
  attribute: string;
  eval: string;
  issuePrettyName: string;
  issueRule: string;
  score: number;
  resolved: boolean;
  resolvedAt: string;
  detailedIssue?: {
    displayName: string;
    evalName: string;
    impactOnItemScore: number;
    issueComments: Array<string>;
    multiScore: unknown[];
    score: number;
  };
}

export interface ExternalSourceData {
  description: string;
  image_url: string;
  images: Record<string, string>;
  name: string;
  origin: Record<string, string>;
  sourceURLs: Array<string>;
}

export interface ProductContentFields {
  attributes: Record<string, { value: string; sources: Record<string, string> }>;
  content: {
    attributesAvailable: boolean;
    definedAttributes: Record<string, string>;
    externalAttributes: Record<string, string>;
    externalData: Record<string, ExternalSourceData>;
    mappedAttributes: Array<[string, string]>;
    processed: Record<string, ProcessedContentAttibute>;
    images: Record<string, string>;
    vision: Record<string, ContentImageVision>;
    imagesOrder?: string[];
  };
  human_content: {
    processed: Record<string, ProcessedContentAttibute>;
  };
  ai_content: {
    processed: Record<string, ProcessedContentAttibute>;
    optimizations?: OptimizationsFields;
  };
  latest_ai_examples?: { attributes: Record<string, Record<string, { value: string; generatedAt: string }>> };
  computed_content?: {
    processed: Record<string, ProcessedContentAttibute>;
  };
  issues?: Array<IssueFields>;
}

export type ExtendedContentEntry = ContentEntry & TargetContentEntry & ProductContentFields;

export interface ProcessedContentAttibute {
  aiModel?: string;
  attribute?: string;
  message?: string;
  valid: boolean;
  value: string;
  score: number;
  weight: number;
  skipped?: boolean;
  unit_of_measure?: string;
  ai_validation?: AIValidationResult;
  price?: number;
  procedure?: string;
}

export interface ProcessedAIHistory {
  generated_at: string;
  model: string;
  price: number;
  version: number;
  process: string;
  ai_template_id?: number | string;
  content: {
    unit?: string;
    value?: string;
    correct_value?: string;
    correct_unit?: string;
    explanation?: string;
    is_valid?: string;
  };
}

export interface VendorsListItem {
  id: number;
  invitation_id: number;
  name: string;
}

export interface InvitedUserItem {
  created_at: string;
  email: string;
  id: number;
  role_id: number;
  vendors: Array<VendorsListItem>;
}

export interface BackendError {
  safeMessage: string;
  traceID: string;
}

export interface BackendErrorResponse {
  errors: Array<BackendError>;
}

export interface Comment {
  comment: string;
  created_at: string;
  entity_id: string;
  entity_type: number;
  name: string;
  id: number;
  payload: string;
  updated_at: string;
  user_id: string;
  replies_count: number;
  replies: Array<Comment>;
}

export interface ProductComment {
  id: number;
  replies_count: number;
  replies: Array<Comment>;
  comment: string;
  user_id: number;
  payload: string;
  created_at: string;
  updated_at: string;
  resolved_at?: string;
  name: string;
  email: string;
  picture: string;
  product_image_url: string;
  product_title: string;
  wpid: string;
  tcin: string;
  item_id: string;
  resolver_name?: string;
  resolver_email?: string;
  resolver_picture?: string;
  tags?: Record<string, string>;
}

export type ScopeId = number | string;

export type EnhanceTemplateData = {
  data: {
    enhancedTemplate: string;
    enhancementPrompt: string;
    originalTemplate: string;
  };
};

export interface ScopeTag {
  id: number;
  psid: number;
  tag: string;
  type: number;
}

export interface Scope {
  created_at?: string;
  ends_at?: string;
  id?: ScopeId;
  items?: number;
  name: string;
  notes?: string;
  starts_at?: string;
  ws_scope?: number;
  categoriesCount?: number;
  productsCount?: number;
  import_status?: number;
  exportLogId?: string;
  approved?: number;
  pendingReviewCount?: number;
  tags?: Array<ScopeTag>;
  is_private?: boolean;
  action?: ActionNotification;
}

export interface ScopeSummary {
  cost: number;
  done: number;
  failed: number;
  ready_for_ai: number;
  ready_for_review: number;
  remaining: number;
  total: number;
  uploaded: number;
  tags: number;
  users_cost: number;
  ai_cost: number;
  total_cost: number;
}

export interface ScopeStats {
  ai_cost: number;
  approved: number;
  completed: number;
  date: string;
  done: number;
  failed: number;
  issue: number;
  ready_for_ai: number;
  ready_for_review: number;
  ready_for_upload: number;
  remaining: number;
  skipped: number;
  tags: number;
  total_cost: number;
  uploaded: number;
  users_cost: number;
}

export interface ScopeSpread {
  ai_processed: number;
  ai_correctness: number;
  ai_pt_complete?: number;
  total_ai: number;
  total_empty_ai: number;
  total_empty_ai_percent: number;
  category: string;
  parentCategory: string;
  done: number;
  issues: number;
  total: number;
  uploaded: number;
  ready_for_upload: number;
  id: number;
  psid: number;
}

export interface CategoryParent {
  id: number;
  name: string;
}

export interface ScopeCategoryStat {
  total_ai: number;
  total_human: number;
  updated_human: number;
  total_empty_ai: number;
  total_empty_ai_percent: number;
  name: string;
  ai_correctness: number;
}

export interface WsScope {
  ws_scope: number;
}

export interface ScopeUser {
  user_id: number;
  email: string;
  name: string;
  picture: string;
  approved: number;
  skipped: number;
  completed: number;
  failed: number;
  last_completed: string;
  spentTime: string;
  spentTimeAvg: string;
}

export interface ITableSort {
  field: string;
  direction: 'asc' | 'desc';
}

export interface IPagination {
  limit?: number;
  offset?: number;
}

export interface ISort {
  sort?: ITableSort;
}

export interface CommentsStats {
  totalCount: number;
  l3Count: number;
  l3Percent: number;
}

export interface ExportResponse {
  id: string;
}

export interface AIInput {
  prompt: string;
  // I think pretty debatable field, can't strict it somehow, so decided to put unknown
  openAIFunctionsDefinitions: Array<Record<string, unknown>>;
  meta: {
    attributeKey: string;
    wpid: string;
    sku: string;
  };
}

export interface ExtraContentStatsDetails {
  skipped?: number;
  assigned?: number;
  available?: number;
  score_95?: number;
  score_80_95?: number;
  score_80?: number;
  reviewed?: number;
  notReviewed?: number;
}

export interface ExtraContentStats {
  uploadedDetails?: ExtraContentStatsDetails;
  reviewDetails?: ExtraContentStatsDetails;
  uploadReadyDetails?: ExtraContentStatsDetails;
}

export interface CategoriesParentsResponse {
  count: number;
  categories: Array<CategoryParent>;
}

export interface AttributesGroup {
  id: number;
  items: Array<{
    order: number;
    name: string;
    id: string;
  }>;
}

export enum QueueBatchNestedFieldType {
  Batches = 'batches',
  WsScopes = 'wsScopes',
  Scopes = 'scopes',
  Categories = 'categories',
  Products = 'products',
}

export type QueueBatchCommonFields = {
  count: number;
  createdAt: string;
  tcins: Array<string>;
} & { [key in QueueBatchNestedFieldType]: Record<string, QueueBatchCommonFields> };

export type QueueBatchEntry = {
  addedAt: string;
  priority: string;
} & QueueBatchCommonFields;

export type QueueStats = Record<string, QueueBatchEntry>;

export type QueueStatusV3 = 'running' | 'finished' | 'failed';

export interface QueueProductV3 {
  tcin: string;
  wpid: string;
  category: string;
  scope: string;
  startedAt: string;
  finishedAt: string;
  status: QueueStatusV3;
  errorMessage: string;
}

export interface QueueStatsV4 {
  id: number;
  requestParams: Record<string, unknown>;
  status: string;
  startedAt: string;
  finishedAt: string;
  runningCount: number;
  finishedCount: number;
  failedCount: number;
  skippedCount: number;
  canceledCount: number;
  totalCount: number;
  attributesGenerationTotalCost: string;
  pdpGenerationTotalCost: string;
}

export type QueueV4Product = {
  wpid?: string;
  tcin?: string;
  category: string;
  scope: string;
  startedAt: Date;
  finishedAt: Date;
  status: string;
  errorMessage: string;
};

export interface AIModel {
  displayName: string;
  name: string;
  additionalInfo: Record<string, string>;
  price: {
    input: string;
    output: string;
  };
}

export interface AIQueueProduct {
  pid?: string;
  tcin?: string;
  name: string;
}

export interface TargetProduct extends ProductContentFields {
  item_type_name: string;
  tcin: string;
  name: string;
  description: string;
  status: string;
  item_type: string;
  product_subtype_id: string;
  product_group_id: string;
  parent_sku: string;
  created_at: number;
  image_url: string;
  category: string;
  feature_bullets?: Array<string>;
  // temporary fields
  tags?: Record<string, string>;
  error_message?: string;
  commentsCount?: number;
  approvedBy?: User;
  approved_by?: User;
  user?: User;
  pid?: string;
  quality_score?: number;
  total_predicted_weight?: number;
}

export interface AIValidationResult {
  correct_value: string;
  is_valid: boolean;
  explanation: string;
}

export enum AttributeType {
  Original = 'original',
  Computed = 'computed',
  Human = 'human',
  AI = 'ai',
  Default = 'default',
}

export interface FormAttributeEntry {
  key: string;
  rules: TemplateRule;
  definedValue: string;
  aiValue: string;
  aiModel: string;
  computedValue: string;
  defaultValue: string;
  initialValue: string;
  humanValue: string;
  backendError: string;
  formValue: string | string[];
  weight: number;
  score: number;
  skipped: boolean;
}

export interface AttributeAITemplate {
  id: number | string;
  version: number;
  template: string;
  description?: string;
  model_name: string;
  created_at: string;
  attribute_id: string;
}

export type AIConditionExpression = Record<string, Record<string, string> | AIConditionExpression[]>;

export interface AIInstructionEntry {
  id: number | string;
  conditions: string;
  instruction: string;
  created_at: string;
  updated_at: string;
  name: string;
  applicability_scope: string;
}

export interface LeaderboardUser {
  user_id: number;
  name: string;
  email: string;
  picture: string;
  max_id: number;
  duration_sec: number;
  products_count: number;
  duration_sec_avg: number;
  products_count_avg: number;
  time_per_product_s: number;
  time_per_product_f: string;
  time_per_product_avg_s: number;
  time_per_product_avg_f: string;
  is_top_rated: boolean;
}

export interface CategoryHistoryEntry {
  updated: string;
  rules: Record<string, string | number>;
  delta: Record<string, string | number>;
}

export type HistoricalRuleObj = Record<string, string | number | Record<string, boolean>>;

export interface AttributeHistoryEntry {
  name: string;
  date: string;
  rules: HistoricalRuleObj;
  delta: HistoricalRuleObj;
  productsCount: number;
  in_scope: boolean;
  productTypes: Array<{
    id: string | number;
    name: string;
    in_scope: number;
  }>;
}

export interface SuggestedProductType {
  possible_pt: string;
  similarity_score: number;
  prod_type: string;
  name: string;
  displayName: string;
}

export interface BenchmarkListItem {
  model: string;
  pid: string;
  user_id: number;
  product_name: string;
  product_image: string;
  user_email: string;
  user_name: string;
  user_image: string;
  attribute_key: string;
}

export interface BenchmarkReport {
  created_at: string;
  id: number;
  models: string[];
  name: string;
  psid: string;
  status: BenchmarkReportStatus;
  ws_scope?: number;
  scope?: number;
}

export enum BenchmarkReportStatus {
  Pending = 1,
  Completed = 2,
}

export interface BenchmarkReportProduct {
  pid?: string;
  tcin?: string;
  report_id: number;
  user_selections: unknown;
  models: Record<
    string,
    {
      product_long_description: string;
      product_name: string;
      product_short_description: string;
    }
  >;
}

export interface SavedKeyword {
  original_keyword: string;
  id: number | string;
  keyword: string;
  source: string;
  selected: boolean;
  relevance_score: number;
  sfr: string;
  procedure: string;
  emphasis_level: number;
  updated_at: string;
  latest_month_volume?: number;
  past_six_months_volume?: number;
  past_3_months_sfr?: number;
  past_6_months_sfr?: number;
  past_12_months_sfr?: number;
  past_month_sfr?: number;
  past_week_sfr?: number;
  autozone_volume?: number;
}

export enum TimeRangesType {
  '1w' = 'past_week',
  '1m' = 'past_month',
  '3m' = 'past_3_months',
  '6m' = 'past_6_months',
  '12m' = 'past_12_months',
}

export interface KeywordSuggestion {
  keyword: string;
  amazon_sfr: string;
  target_latest_month_volume: string;
  target_past_six_months_volume: string;
  walmart_sfr: string;
}

export enum SEOKeywordEmphasisLevel {
  Normal = 1,
  Medium = 2,
  High = 3,
}

export interface PDPType {
  value: string;
  displayName: string;
  processDisplayName: string;
  promptType: string;
}

export enum GSheetImportStatus {
  IMPORT_IN_PROGRESS = 'IMPORT_IN_PROGRESS',
  IMPORT_SUCCESS = 'IMPORT_SUCCESS',
  IMPORT_FAILED = 'IMPORT_FAILED',
  PRODUCT_UPDATE_IN_PROGRESS = 'PRODUCT_UPDATE_IN_PROGRESS',
  PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS',
  PRODUCT_UPDATE_FAILED = 'PRODUCT_UPDATE_FAILED',
}

export interface GSheetImport {
  id: number;
  name: string;
  type: string;
  status: GSheetImportStatus;
  meta: {
    idColumn: string;
    sheetName: string;
    exportResult?: {
      found: number;
      total: number;
      notFound: number;
      headers: string[];
    };
    updateResult?: {
      matched: number;
      modified: number;
    };
    updatePayload?: {
      mapping: Array<{
        column: string;
        attribute: string;
      }>;
      updateHuman: boolean;
      updateOrigin: boolean;
      updateAIOptimization: boolean;
    };
    spreadsheetID: string;
  };
  created_at: string;
}

export interface GSheetPreviewRow {
  attribute: string;
  pid: string;
  productContent: { origin?: string; aiOptimization?: string; ai?: string; human?: string };
  value: string;
}

interface SearchedAttributeBase {
  name: string;
}

type SearchedAttributeScopeData<T> = T extends true
  ? {
      total: number;
      in_scope: number;
      out_scope: number;
    }
  : object;

export type SearchedAttribute<T extends boolean> = SearchedAttributeBase & SearchedAttributeScopeData<T>;

export enum AnthropicClient {
  AnthropicAPI = 1,
  VertexAI = 2,
}

export interface GlobalAISettings {
  anthropic_client: AnthropicClient;
  anthropic_rate_limit: number;
  updated_at: string;
}

export enum ActionNotificationType {
  ProductsImport = 'products-import',
  ExportProductsPDP = 'export-products-pdp',
  ExportProductsCSV = 'export-products-csv',
  ExportProductsGsheet = 'export-products-gsheet',
  ExportProductsIssuesGsheet = 'export-products-issues-gsheet',
  ExportProductsGsheetCompare = 'export-products-compare-gsheet',
  ExportPT = 'export-pt',
  ExportSyndigoDownload = 'export-syndigo-download',
}

export enum ActionNotificationStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  STOPPED = 'STOPPED',
}

type ActionNotificationMetaPayload =
  // Walmart & Flywheel import
  | {
      itemsCount: number;
      scopeID?: number;
    }
  // Syndigo
  | {
      productsFile: string;
      productsFilePath?: string;
      imagesFile?: string;
      imagesFilePath?: string;
      scopeID?: number;
    }
  // Walmart export
  | {
      categories?: string;
      scopes?: string;
      wpIDs?: string;
      wsScope?: number;
      exportToJson?: boolean;
    }
  // Walmart Gsheet export
  | {
      categories?: string;
      scopes?: string;
      wsScope?: number;
      limit?: number;
      chunkLimit?: number;
      statuses?: string;
      wpIDs?: string;
      exportType?: boolean;
    }
  // Brand / Image analysis
  | {
      productID?: string;
    };

type ActionNotificationMetaResult = {
  itemsCount: number;
  errorsCount?: number;
  failedItems?: string[];
  notFoundItems?: string[];
} & {
  itemsCount: number;
  errorsCount?: number;
  categoriesCount?: number;
  categoriesErrorsCount?: number;
} & {
  itemsCount: string;
  categoriesCount?: number;
  uncategorizedCount?: number;
};

type ActionNotificationMeta = {
  commandID?: number;
  exportID?: number;
  payload: ActionNotificationMetaPayload;
  result: ActionNotificationMetaResult;
};

export interface ActionNotification {
  id: number;
  initiator_id: number;
  type: ActionNotificationType;
  status: ActionNotificationStatus;
  vendor_id: number;
  vendor_name: string;
  message: string;
  meta: ActionNotificationMeta;
  created_at: string;
  updated_at: string;
}

export interface ConditionalFieldValues {
  brand: string;
  productTitle: string;
  productDescription: string;
  productFeatures: string;
  productType: string;
}

export enum PDPGenerationPipelineStatus {
  PREPROCESSING = 'PREPROCESSING',
  PRODUCT_TYPE_SELECTION_PENDING = 'PRODUCT_TYPE_SELECTION_PENDING',
  CONTENT_GENERATION = 'CONTENT_GENERATION',
  COMPLETED = 'COMPLETED',
}

export interface PDPGenPipeline {
  id: number;
  status: PDPGenerationPipelineStatus;
  updated_at: string;
  completed_at: string;
  input_data: {
    productUrls?: string[];
    title?: string;
    description?: string;
    scopeID?: number;
    scopePayload: {
      name: string;
      items: number;
      notes: string;
      ends_at: string;
      starts_at: string;
      is_private: boolean;
    };
    pdpGeneration: boolean;
    sub_vendor_id: number;
    pdpSeoOptimization: boolean;
    attributesGeneration: boolean;
    includeBrandAnalysis: boolean;
    includeImageAnalysis: boolean;
  };
  preprocessing_result: {
    images: string[];
    productData: Record<string, unknown>;
    suggestedProductTypes: string[];
  } | null;
  product_type_selection_result: {
    productType: {
      prod_type: string;
    };
  } | null;
  content_generation_result: {
    tcin: string;
  } | null;
  sub_vendor_id: number;
  error_message: string;
  created_at: string;
}

export enum UserFlag {
  ManageTeamSettings = 'can_manage_team_settings',
  TestUser = 'is_test_user',
}

export interface SupplierEntry {
  id: number;
  name: string;
  partner_id: string;
  skuninja_account_id: number;
  token4: string;
  last_synced_at: string;
}

export interface SupplierProductEntry {
  created_at: string;
  gtin: string;
  hash: string;
  item_id: string;
  last_synced_at: string;
  sku: string;
  supplier_id: number;
  supplier_name: string;
  upc: string;
  wpid: string;
}

export interface ImageAnalysisBatchEntry {
  id: number;
  inputParams: Record<string, unknown>;
  createdAt: string;
  totalCount: number;
  totalCost: number;
  finishedCount: number;
  runningCount: number;
  failedCount: number;
}

export interface ImageAnalysisBatchProductEntry {
  wpid: string;
  tcin: string;
  batchID: number;
  errorMessage: string;
  startedAt: string;
  finishedAt: string;
}
