import { useMutation } from '@tanstack/react-query';
import { RetailerType, SyndigoRetailers } from 'common/constants/entities';
import { contentApi } from 'common/services/api/content/content-api.service';
import {
  AddCostcoBody,
  AddTcinsBody,
  AddWpidsBody,
  AttachProductFlagBody,
  DryExportBody,
  GetPtRecommendationsParams,
  PatchBrandsAnalysisAttributesBody,
  PatchBulkStatusBody,
  PatchProductsStatusBody,
  PatchTargetBody,
  ProductSaveBody,
  UploadTargetBody,
} from 'common/services/api/content/content-api.types';
import { userService } from 'common/services/user.service';
import { ScopeId } from 'common/types/common';

export function useProductsImportMutation() {
  const mutation = useMutation({
    mutationFn: contentApi.importProducts,
  });

  return mutation;
}

export function useWalmartProductImportMutation(body: AddWpidsBody) {
  const mutation = useMutation({
    mutationFn: () => contentApi.importWalmartProduct(body),
  });

  return mutation;
}

export function useSearchSuppliersProductsMutation() {
  const mutation = useMutation({
    mutationFn: async (itemsIDs: Array<string>) => {
      const { data } = await contentApi.searchSuppliersProducts(itemsIDs);
      return data.suppliersProducts;
    },
  });

  return mutation;
}

export function useRetailerProductImportMutation(body: AddTcinsBody) {
  const mutation = useMutation({
    mutationFn: () => {
      const apiRetailer = userService.getAPIRetailer() as RetailerType;
      const retailer = userService.getAPIRetailer() as RetailerType;
      const subRetailer = userService.getSubRetailer();

      const { files, images, ...bodyData } = body;

      return userService.ensureRetailers(SyndigoRetailers) || userService.getRetailer() === RetailerType.Autozone
        ? contentApi.importSyndigoProduct(apiRetailer, retailer, subRetailer, bodyData, files, images)
        : contentApi.importRetailerProduct(apiRetailer, bodyData);
    },
  });

  return mutation;
}

export function useRetailerImportStatusMutation() {
  const mutation = useMutation({
    mutationFn: async (id: string) => {
      const retailer = userService.getAPIRetailer() as RetailerType;

      const { data } = await contentApi.getRetailerImportStatus(retailer, id);
      return data;
    },
  });

  return mutation;
}

export function useWalmartImportStatusMutation() {
  const mutation = useMutation({
    mutationFn: async (id: string) => {
      const { data } = await contentApi.getWalmartImportStatus(id);
      return data;
    },
  });

  return mutation;
}

export function useProductSaveMutation(wpid: string) {
  const mutation = useMutation({
    mutationFn: async (data: ProductSaveBody) => {
      return contentApi.saveProduct(wpid, data);
    },
  });

  return mutation;
}

export function useRetailerProductSaveMutation(tcin: string) {
  const mutation = useMutation({
    mutationFn: async (data: ProductSaveBody) => {
      const retailer = userService.getAPIRetailer() as RetailerType;

      return contentApi.saveRetailerProduct(retailer, tcin, data);
    },
  });

  return mutation;
}

export function useProductsSaveMutation() {
  const mutation = useMutation({
    mutationFn: async (data: Array<ProductSaveBody>) => {
      return Promise.all(data.map(body => contentApi.saveProduct(body.pid, { ...body, pid: undefined })));
    },
  });

  return mutation;
}

export function useSaveCustomAttributeMutation() {
  const mutation = useMutation({
    mutationFn: async (data: { wpid: string; key: string; value: string }) => {
      return contentApi.saveProduct(data?.wpid, {
        attributes: {
          [data?.key]: { value: data?.value },
        },
      });
    },
  });

  return mutation;
}

export function useRetailerProductsSaveMutation() {
  const mutation = useMutation({
    mutationFn: async (data: Array<ProductSaveBody>) => {
      const retailer = userService.getAPIRetailer() as RetailerType;

      return Promise.all(
        data.map(body => contentApi.saveRetailerProduct(retailer, body.tcin ?? body.pid, { ...body, tcin: undefined }))
      );
    },
  });

  return mutation;
}

export function usePatchRetailerProductsStatusMutation() {
  const mutation = useMutation({
    mutationFn: async (body: PatchProductsStatusBody) => {
      const retailer = userService.getAPIRetailer() as RetailerType;

      return contentApi.patchProductsStatus(retailer, body);
    },
  });

  return mutation;
}

export function usePatchWalmartProductsStatusMutation() {
  const mutation = useMutation({
    mutationFn: async (body: PatchProductsStatusBody) => {
      return contentApi.patchWalmartProductsStatus(body);
    },
  });

  return mutation;
}

export function usePatchWalmartBulkStatusMutation() {
  const mutation = useMutation({
    mutationFn: async (body: PatchBulkStatusBody) => {
      const { data } = await contentApi.patchWalmartBulkStatus(body);
      return data;
    },
  });

  return mutation;
}

export function useSaveRetailerCustomAttributeMutation() {
  const mutation = useMutation({
    mutationFn: async (data: { tcin: string; key: string; value: string }) => {
      const retailer = userService.getAPIRetailer() as RetailerType;

      return contentApi.saveRetailerProduct(retailer, data?.tcin, {
        attributes: {
          [data?.key]: { value: data?.value },
        },
      });
    },
  });

  return mutation;
}

export function useProductWalmartFlagPostMutation(wpid: string) {
  const mutation = useMutation({
    mutationFn: (data: AttachProductFlagBody) => contentApi.attachWalmartFlag(wpid, data),
  });

  return mutation;
}

export function useProductRetailerFlagPostMutation(tcin: string) {
  const mutation = useMutation({
    mutationFn: (data: AttachProductFlagBody) => {
      const retailer = userService.getAPIRetailer() as RetailerType;
      return contentApi.attachRetailerFlag(retailer, tcin, data);
    },
  });

  return mutation;
}

export function useProductWalmartFlagsDeleteMutation(wpid: string) {
  const mutation = useMutation({
    mutationFn: (data: Array<string>) => contentApi.detachWalmartFlags(wpid, data),
  });

  return mutation;
}

export function useProductRetailerFlagsDeleteMutation(tcin: string) {
  const mutation = useMutation({
    mutationFn: (data: Array<string>) => {
      const retailer = userService.getAPIRetailer() as RetailerType;
      return contentApi.detachRetailerFlags(retailer, tcin, data);
    },
  });

  return mutation;
}

export function useResetValueMutation() {
  const mutation = useMutation({
    mutationFn: contentApi.resetValue,
  });

  return mutation;
}

export function useDryExportMutation(body: DryExportBody) {
  const mutation = useMutation({
    mutationFn: async () => {
      const { data } = await contentApi.dryExport(body);
      return data.id;
    },
  });

  return mutation;
}

export function usePdpWalmartExportMutation(body: DryExportBody) {
  const mutation = useMutation({
    mutationFn: async () => {
      const { data } = await contentApi.pdpWalmartExport(body);
      return data.id;
    },
  });

  return mutation;
}

export function useGSheetExportMutation(body: DryExportBody) {
  const mutation = useMutation({
    mutationFn: async () => {
      const { data } = await contentApi.gsheetExport(body);
      return data.id;
    },
  });

  return mutation;
}

export function useUploadTargetProductsMutation(body: UploadTargetBody) {
  const mutation = useMutation({
    mutationFn: async () => {
      const { data } = await contentApi.uploadTargetProducts(body);
      return data.id;
    },
  });

  return mutation;
}

export function useUploadSyndigoProductsMutation(body: UploadTargetBody) {
  const mutation = useMutation({
    mutationFn: async () => {
      const { data } = await contentApi.uploadSyndigoProducts(body);
      return data.id;
    },
  });

  return mutation;
}

export function useGSheetScopeExportMutation(body: DryExportBody) {
  const mutation = useMutation({
    mutationFn: async () => {
      const retailer = userService.getAPIRetailer().toLowerCase();
      const { data } = await contentApi.gsheetScopeExport(body, retailer);
      return data.id;
    },
  });

  return mutation;
}

export function usePatchTargetScopeMutation() {
  const mutation = useMutation({
    mutationFn: (data: { id: ScopeId; body: PatchTargetBody }) => {
      const retailer = userService.getAPIRetailer().toLowerCase();
      return contentApi.patchTargetScope(data, retailer);
    },
  });

  return mutation;
}

export function useCostcoProductImportMutation(body: AddCostcoBody) {
  const mutation = useMutation({
    mutationFn: () => {
      const retailer = userService.getAPIRetailer() as RetailerType;

      return contentApi.importRetailerProduct(retailer, body);
    },
  });

  return mutation;
}

export function useWalmartImportResultMutation() {
  const mutation = useMutation({
    mutationFn: async (id: string) => {
      const { data } = await contentApi.getWalmartImportResult({ id, includeErrorPayload: true });
      return data;
    },
  });

  return mutation;
}

export function usePatchBrandsAnalysisAttributesMutation() {
  const mutation = useMutation({
    mutationFn: async (body: PatchBrandsAnalysisAttributesBody) => {
      const retailer = userService.getAPIRetailer() as RetailerType;

      return userService.getRetailer() === RetailerType.Walmart
        ? contentApi.patchWalmartBrandsAttributes(body)
        : contentApi.patchBrandsAnalysisAttributes(body, retailer);
    },
  });

  return mutation;
}

export function usePtRecommendationsMutation(params: GetPtRecommendationsParams) {
  const mutation = useMutation({
    mutationFn: async () => {
      const retailer = userService.getAPIRetailer().toLowerCase();
      const { data } = await contentApi.getPtRecommendations(params, retailer);
      return data;
    },
  });

  return mutation;
}
