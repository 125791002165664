/* eslint-disable react/no-array-index-key */
import { Box, IconButton, Popover, Typography } from '@mui/material';
import { MouseEvent, ReactElement, useState } from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import { Styles } from 'common/types/styles';

const styles: Styles = {
  container: {
    p: 2,
    display: 'flex',
    flexDirection: 'column',
  },
  rulesTitle: {
    fontWeight: 'bold',
  },
};

enum RULES_NAV {
  maximum_value = 'Exceeds Maximum Value',
  minimum_value = 'Below Minimum Value',
  banned_special_chars = 'Contains Banned Special Characters',
  minimum_words_count = 'Insufficient Words Count',
  maximum_words_count = 'Exceeds Words Count Limit',
  minimum_bullet_count = 'Below Minimum Bullet Count',
  maximum_bullet_count = 'Exceeds Maximum Bullet Count',
}

interface Props {
  data: string[];
}

export function WarningRulesPopover({ data }: Props): ReactElement {
  const [buttonAnchor, setButtonAnchor] = useState<HTMLButtonElement>(null);

  const handlePopoverOpen = (e: MouseEvent<HTMLButtonElement>) => {
    setButtonAnchor(e.currentTarget);
  };

  const handlePopoverClose = () => {
    setButtonAnchor(null);
  };

  return (
    <>
      <IconButton size="small" onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
        <WarningIcon color="warning" fontSize="small" />
      </IconButton>
      <Popover
        open={Boolean(buttonAnchor)}
        anchorEl={buttonAnchor}
        onClose={handlePopoverClose}
        sx={{
          pointerEvents: 'none',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableRestoreFocus
      >
        <Box sx={styles.container}>
          <Typography variant="subtitle2" sx={styles.rulesTitle}>
            Warning Rules:
          </Typography>
          <Box>
            {data?.map(r => (
              <Typography variant="subtitle2">&bull; {RULES_NAV[r as keyof typeof RULES_NAV]}</Typography>
            ))}
          </Box>
        </Box>
      </Popover>
    </>
  );
}
