import { userService } from 'common/services/user.service';
import { PocRetailers, RetailerType, SyndigoRetailers } from './entities';

const ensureL3Walmart = () => userService.ensureRetailer(RetailerType.Walmart) && userService.ensureLevel('L3');

export const RoutesAvailability = {
  Overview: () => {
    if (ensureL3Walmart()) return false;
    return userService.isAdmin() || userService.ensureLevel('L3');
  },

  ContentManagement: () => {
    return (
      (userService.isAdmin() || userService.ensureLevel('L1') || userService.ensureLevel('L3')) &&
      userService.ensureRetailer(RetailerType.Walmart)
    );
  },

  ContentReview: () => {
    return userService.isAdmin() || userService.ensureLevel('L1') || userService.ensureLevel('L3');
  },

  ProductReview: () => {
    if (ensureL3Walmart()) return false;

    return (
      ((userService.isAdmin() || userService.ensureLevel('L1')) &&
        (userService.ensureRetailer(RetailerType.Target) ||
          userService.ensureRetailer(RetailerType.Instacart) ||
          userService.ensureRetailer(RetailerType.Autozone) ||
          userService.ensureRetailers(SyndigoRetailers) ||
          userService.ensureRetailers(PocRetailers))) ||
      userService.ensureLevel('L3')
    );
  },

  TemplatesManagement: () => {
    return (userService.isAdmin() || userService.ensureLevel('L1')) && userService.ensureRetailer(RetailerType.Walmart);
  },

  TemplateEditor: () => {
    return (userService.isAdmin() || userService.ensureLevel('L1')) && userService.ensureRetailer(RetailerType.Walmart);
  },

  Comments: () => {
    return (
      (userService.isAdmin() || userService.ensureLevel('L1') || userService.ensureLevel('L3')) &&
      userService.ensureRetailers([
        ...SyndigoRetailers,
        ...PocRetailers,
        RetailerType.Walmart,
        RetailerType.Target,
        RetailerType.Flywheel,
      ])
    );
  },

  AIQueue: () => {
    return userService.isAdmin() || userService.ensureLevel('L3');
  },

  KeywordsTool: () => {
    return userService.ensureRetailers([RetailerType.Amazon, RetailerType.Target, RetailerType.Flywheel]);
  },

  AIInstructions: () => {
    return userService.isAdmin() || userService.ensureLevel('L3');
  },

  AIBenchmark: () => {
    if (userService.ensureLevel('L3')) return false;

    return (
      !userService.ensureRetailers([
        ...SyndigoRetailers,
        ...PocRetailers,
        RetailerType.Autozone,
        RetailerType.Flywheel,
      ]) && userService.isAdmin()
    );
  },

  CompareBenchmark: () => {
    if (userService.ensureLevel('L3')) return false;

    return (
      userService.isAdmin() &&
      !userService.ensureRetailers([...PocRetailers, ...SyndigoRetailers, RetailerType.Autozone, RetailerType.Flywheel])
    );
  },

  CompareBenchmarkStats: () => {
    if (userService.ensureLevel('L3')) return false;

    return (
      userService.isAdmin() &&
      !userService.ensureRetailers([...PocRetailers, ...SyndigoRetailers, RetailerType.Autozone, RetailerType.Flywheel])
    );
  },

  AIBenchmarkStats: () => {
    if (userService.ensureLevel('L3')) return false;

    return (
      !userService.ensureRetailers([
        ...SyndigoRetailers,
        ...PocRetailers,
        RetailerType.Autozone,
        RetailerType.Flywheel,
      ]) && userService.isAdmin()
    );
  },

  CategoriesSettings: () => {
    return userService.ensureRetailers([
      RetailerType.Flywheel,
      RetailerType.Instacart,
      RetailerType.Autozone,
      RetailerType.Target,
      ...SyndigoRetailers,
      ...PocRetailers,
    ]);
  },

  AddTCINs: () => {
    if (ensureL3Walmart()) return false;

    return userService.ensureRetailer(RetailerType.Target) && (userService.isAdmin() || userService.ensureLevel('L3'));
  },

  AddASINs: () => {
    if (ensureL3Walmart()) return false;

    return userService.ensureRetailer(RetailerType.Amazon) && (userService.isAdmin() || userService.ensureLevel('L3'));
  },

  AddXLSX: () => {
    if (ensureL3Walmart()) return false;

    return userService.ensureRetailers(SyndigoRetailers) && (userService.isAdmin() || userService.ensureLevel('L3'));
  },

  ChangeTracking: () => {
    if (ensureL3Walmart()) return false;

    return (
      (userService.ensureRetailer(RetailerType.Target) || userService.ensureRetailer(RetailerType.Walmart)) &&
      (userService.isAdmin() || userService.ensureLevel('L3'))
    );
  },

  AddSKUs: () => {
    return (
      (userService.ensureRetailer(RetailerType.Walmart) || userService.ensureRetailer(RetailerType.Flywheel)) &&
      (userService.isAdmin() || userService.ensureLevel('L3'))
    );
  },

  AISearch: () => {
    return (
      userService.ensureRetailer(RetailerType.Target) ||
      userService.ensureRetailers(SyndigoRetailers) ||
      userService.ensureRetailer(RetailerType.Autozone) ||
      userService.ensureRetailer(RetailerType.Instacart)
    );
  },

  SwatchCreator: () => {
    if (ensureL3Walmart()) return false;

    return userService.ensureRetailer(RetailerType.Walmart) && (userService.isAdmin() || userService.ensureLevel('L3'));
  },

  BackgroundCreator: () => {
    if (ensureL3Walmart()) return false;

    return userService.isAdmin();
  },

  Performance: () => {
    return !userService.ensureRetailer(RetailerType.Autozone) && !userService.ensureRetailers(SyndigoRetailers);
  },

  Leaderboard: () => {
    if (userService.ensureLevel('L3')) return false;

    return (
      !userService.ensureRetailers([...SyndigoRetailers, ...PocRetailers, RetailerType.Autozone]) &&
      (userService.isAdmin() || userService.ensureLevel('L1'))
    );
  },

  GSheetBatchUpdate: () => {
    return userService.ensureRetailer(RetailerType.Walmart) && userService.isAdmin();
  },

  PDPGeneration: () => {
    return userService.ensureRetailer(RetailerType.Amazon) || userService.ensureRetailer(RetailerType.Target);
  },

  OverviewPage: () => {
    return userService.isAdmin() || userService.isL3();
  },

  PDP: () => true,
  QuickReview: () => true,
  QuickStart: () => true,
  Support: () => userService.isAdmin(),
  Settings: () => userService.isAdmin() || userService.ensureLevel('L3'),
  ImageScoring: () => userService.isAdmin(),
  SanitizePreview: () => true,
  WarningRules: () => true,
  AISettings: () => true,
  Suppliers: () => true,
};
