import { useMutation } from '@tanstack/react-query';
import { RetailerType } from 'common/constants/entities';
import { aiPromptsApi } from 'common/services/api/ai-prompts/ai-prompts.service';
import {
  CreatePromptBody,
  ResetModelBody,
  SelectAttributeModelBody,
  SelectModelBody,
  SelectVersionPromptBody,
} from 'common/services/api/ai-prompts/ai-prompts.types';
import { userService } from 'common/services/user.service';

export function useSelectModelMutation() {
  const mutation = useMutation({
    mutationFn: async (body: SelectModelBody) => {
      const retailer = userService.getAPIRetailer() as RetailerType;
      const { data } =
        retailer === RetailerType.Walmart
          ? await aiPromptsApi.selectWalmartModel(body)
          : await aiPromptsApi.selectRetailerModel(retailer, body);
      return data;
    },
  });

  return mutation;
}

export function useSelectVersionMutation() {
  const mutation = useMutation({
    mutationFn: async (body: SelectVersionPromptBody) => {
      const retailer = userService.getAPIRetailer() as RetailerType;
      const { data } =
        retailer === RetailerType.Walmart
          ? await aiPromptsApi.selectWalmartPrompt(body)
          : await aiPromptsApi.selectRetailerPrompt(retailer, body);
      return data;
    },
  });

  return mutation;
}

export function useCreatePromptMutation() {
  const mutation = useMutation({
    mutationFn: async (body: CreatePromptBody) => {
      const retailer = userService.getAPIRetailer() as RetailerType;
      const { data } =
        retailer === RetailerType.Walmart
          ? await aiPromptsApi.createWalmartPrompt(body)
          : await aiPromptsApi.createRetailerPrompt(retailer, body);
      return data;
    },
  });

  return mutation;
}

export function useSelectAttributeModelMutation() {
  const mutation = useMutation({
    mutationFn: async (body: SelectAttributeModelBody) => {
      const retailer = userService.getAPIRetailer() as RetailerType;
      const { data } =
        retailer === RetailerType.Walmart
          ? await aiPromptsApi.selectWalmartAttributeModel(body)
          : await aiPromptsApi.selectAttributeModel(retailer, body);
      return data;
    },
  });

  return mutation;
}

export function useResetModelMutation() {
  const mutation = useMutation({
    mutationFn: async (body: ResetModelBody) => {
      const retailer = userService.getAPIRetailer() as RetailerType;
      const { data } =
        retailer === RetailerType.Walmart
          ? await aiPromptsApi.resetWalmartModel()
          : await aiPromptsApi.resetRetailerModel(retailer, body);
      return data;
    },
  });

  return mutation;
}
