import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from '@mui/material';
import { ButtonWithLoading } from 'common/ui/containers/button-with-loading';
import { nanoid } from 'nanoid';
import { ChangeEvent, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { Styles } from 'common/types/styles';
import {
  useAddSuggestedKeyword,
  useFetchKeywords,
  useUpdatePDPKeywordsMutation,
} from 'common/hooks/api/mutations/use-attributes-mutation';
import { useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import InfoIcon from '@mui/icons-material/Info';
import {
  ITableSort,
  KeywordSuggestion,
  SavedKeyword,
  SEOKeywordEmphasisLevel,
  TimeRangesType,
} from 'common/types/common';
import {
  AttributesQueryKey,
  useKeywordsQuery,
  useKeywordsSuggestionsQuery,
} from 'common/hooks/api/queries/use-attributes-query';
import { LightTooltip } from 'common/ui/containers/light-tooltip';
import { HashContainer } from 'common/ui/containers/hash-container';
import { getSortDirection } from 'common/utils/sort';
import { userService } from 'common/services/user.service';
import { RetailerType } from 'common/constants/entities';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import { FieldLabelWrapper } from 'common/ui/inputs/field-label-wrapper';
import { ExpandableSlider } from 'common/ui/inputs/expandable-slider';
import ReplayIcon from '@mui/icons-material/Replay';
import { TableContainerWithLoading } from 'common/ui/containers/table-container-with-loading';
import { useCQRSFetchExternalAttrMutation } from 'common/hooks/api/mutations/use-cqrs-mutation';
import { tryJSONParse } from 'common/utils/json';
import { ContentQueryKey } from 'common/hooks/api/queries/use-content-query';
import { useGeneratePDP } from './use-generate-pdp';
import { ApproveModal } from '../approve-modal';
import { KWInput } from './kw-input';
import { NewKWModal } from './new-kw-modal';
import { ConditionType, KWsSuggestionsFilter } from './kws-suggestions-filter';
import { KWsFilter } from './kws-filters';

enum SortField {
  SFR = 'sfr',
  EmphasisLevel = 'emphasis_level',
  Keyword = 'keyword',
  Volume6 = 'past_six_months_volume',
  Volume1 = 'latest_month_volume',
  AutozoneVolume = 'autozone_volume',
}

const styles: Styles = {
  container: { display: 'flex', flexDirection: 'column' },
  keywords: {
    overflowY: 'auto',
    height: 540,
  },
  pool: { overflowY: 'auto', height: 540 },
  controlBtn: { width: 250 },
  keyword: { display: 'flex', alignItems: 'center', gap: 1 },
  field: { flexGrow: 1 },
  settings: { display: 'flex', alignItems: 'center', gap: 2 },
  emphasisContainer: {
    maxWidth: 300,
    width: '100%',
    mr: 3,
  },
  emphasisSlider: { width: 120 },
  procedureContainer: { display: 'flex', justifyContent: 'center' },
  tableHead: { height: 80 },
  btns: { mt: 2, display: 'flex', gap: 2 },
  rawKW: { width: 100, whiteSpace: 'wrap' },
  keywordWrapper: { display: 'flex', alignItems: 'center', gap: 2 },
  info: { wordBreak: 'break-word', whiteSpace: 'pre-line' },
  volumn: { display: 'flex', flexDirection: 'column', gap: 2 },
  volWrapper: { display: 'grid', flexDirection: 'row', alignItems: 'center', gridTemplateColumns: '32px auto' },
  suggestionsSwitch: { display: 'flex', alignItems: 'center' },
  switchLabel: { fontSize: 12 },
  poolLabelCol: { whiteSpace: 'nowrap' },
  emphasis: { fontSize: 14 },
};

const empasisLVLMarks = [
  {
    value: SEOKeywordEmphasisLevel.Normal,
    label: 'Normal',
  },
  {
    value: SEOKeywordEmphasisLevel.Medium,
    label: 'Medium',
  },
  {
    value: SEOKeywordEmphasisLevel.High,
    label: 'High',
  },
];

interface Props {
  pid: string;
  kwTimeRange?: string;
}

export function PDPKeywords({ pid, kwTimeRange }: Props): ReactElement {
  const queryClient = useQueryClient();

  const [keywords, setKeywords] = useState<Partial<SavedKeyword>[]>([]);
  const [sort, setSort] = useState<ITableSort>({
    field: userService.ensureRetailer(RetailerType.Target) ? SortField.Volume1 : SortField.SFR,
    direction: 'desc',
  });
  const [poolExpanded, setPoolExpanded] = useState<boolean>(true);
  const [regenerateModalOpen, setRegenerateModalOpen] = useState<boolean>(false);
  const [newKWModalOpen, setNewKWModalOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [submittedSearch, setSubmittedSearch] = useState<string>('');
  const [conditionType, setConditionType] = useState<ConditionType>(null);
  const [conditionValue, setConditionValue] = useState<number>(null);
  const [timeRange, setTimeRange] = useState<string>(kwTimeRange ?? TimeRangesType['1m']);
  const [cqrsID, setCQRSID] = useState<string>('');
  const [isCQRSLoading, setIsCQRSLoading] = useState<boolean>(false);

  const cqrsInterval = useRef<number>();

  const { mutate: cqrs } = useCQRSFetchExternalAttrMutation();
  const { data: keywordsData, isFetching: isKeywordsFetching } = useKeywordsQuery(pid, null, {
    ...(timeRange && userService.ensureRetailer(RetailerType.Amazon) ? { time_range: timeRange } : {}),
  });
  const { data: suggestionsData, isFetching: isSuggestionsFetching } = useKeywordsSuggestionsQuery({
    query: submittedSearch,
    num_of_results: 25,
    sub_vendor_id: userService.getSubRetailer(),
  });

  const { mutate: mutateFetchKWs, isLoading: isKWFetching } = useFetchKeywords({
    ...(timeRange && userService.ensureRetailer(RetailerType.Amazon) ? { timeRange } : {}),
  });
  const { mutate: mutateKWs, isLoading: isSaving } = useUpdatePDPKeywordsMutation();
  const { mutate: addSuggestion, isLoading: isAddingSuggestion } = useAddSuggestedKeyword();
  const { handleGenerateAI } = useGeneratePDP({
    issuesOnly: false,
    pdpGen: false,
    seoGen: true,
    pid,
  });

  const getInfo = (kw: Partial<SavedKeyword>) => {
    let res = '';

    if (kw.original_keyword) res += `Raw Keyword: "${kw.original_keyword}"`;
    if (kw.procedure) res += `\nProcedure: "${kw.procedure}"`;

    return res;
  };

  const getSfr = (kw: Partial<SavedKeyword>) => {
    switch (timeRange) {
      case TimeRangesType['1w']:
        return kw?.past_week_sfr;
      case TimeRangesType['1m']:
        return kw?.past_month_sfr;
      case TimeRangesType['3m']:
        return kw?.past_3_months_sfr;
      case TimeRangesType['6m']:
        return kw?.past_6_months_sfr;
      case TimeRangesType['12m']:
        return kw?.past_12_months_sfr;
      default:
        return kw?.sfr;
    }
  };

  const sortedKWs = useMemo(() => {
    const kws = keywords.filter(kw => !kw.id.toString().includes('deleted'));
    if (sort?.field) {
      const fieldType = kws.filter(kw => typeof kw[sort.field as keyof SavedKeyword] === 'string').length
        ? 'string'
        : 'number';
      kws.sort((a, b) => {
        const aField = a[sort.field as keyof SavedKeyword];
        const bField = b[sort.field as keyof SavedKeyword];

        const numberCompare = (a: number, b: number) => (sort.direction === 'asc' ? a - b : b - a);
        const stringCompare = (a: string, b: string) =>
          sort.direction === 'asc' ? a.localeCompare(b) : b.localeCompare(a);

        if (fieldType === 'string') {
          return stringCompare(aField ? String(aField) : '', bField ? String(bField) : '');
        }

        return numberCompare(aField ? Number(aField) : -999, bField ? Number(bField) : -999);
      });
    }
    return kws;
  }, [keywords, sort.direction, sort?.field]);

  const flattenKWs = useMemo(() => {
    return keywords.map(kw => kw.keyword);
  }, [keywords]);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleRegenerateOpen = () => {
    setRegenerateModalOpen(true);
  };

  const handleRegenerateClose = () => {
    setRegenerateModalOpen(false);
  };

  const addKW = (keyword: string) => {
    setKeywords(kws => [...kws, { keyword, id: `new_${nanoid()}`, selected: true, emphasis_level: 1 }]);
  };

  const deleteKW = (id: number | string) => {
    setKeywords(kws =>
      id.toString().includes('new')
        ? kws.filter(kw => kw.id !== id)
        : kws.map(kw => {
            if (kw.id === id) return { ...kw, id: `deleted_${id}` };
            return kw;
          })
    );
  };

  const handlePoolExpanded = () => {
    setPoolExpanded(poolExpanded => !poolExpanded);
  };

  const handleSortCreation = (field: SortField) => () => {
    setSort({ field, direction: getSortDirection(field, sort) });
  };

  const handlePool = (kwID: number | string, pool: boolean) => {
    mutateKWs(
      {
        patch: {
          entries: [
            {
              id: Number(kwID),
              selected: !pool,
            },
          ],
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([AttributesQueryKey.Keywords, pid]);
          toast.success('PDP Keywords saved successfully');
          handleRegenerateOpen();
        },
      }
    );
  };

  const handleSuggestion = (suggestion: KeywordSuggestion) => {
    addSuggestion(
      {
        tcin: pid,
        entries: [{ ...suggestion, emphasis_level: 1, selected: true }],
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([AttributesQueryKey.Keywords, pid]);
          toast.success('PDP Keyword suggestion saved successfully');
          handleRegenerateOpen();
        },
      }
    );
  };

  const handleSave = () => {
    const postKWs = keywords.filter(kw => kw.id.toString().includes('new') && kw.keyword);
    const delKWs = keywords.filter(kw => kw.id.toString().includes('deleted'));
    const patchKWs = keywords.filter(
      kw =>
        !postKWs.some(pkw => pkw.id === kw.id) &&
        !delKWs.some(pkw => pkw.id === kw.id) &&
        keywordsData.keywords.find(savedKW => savedKW.id === kw.id).keyword !== kw.keyword
    );

    mutateKWs(
      {
        post: {
          tcin: pid,
          entries: postKWs.map(kw => ({
            keyword: kw.keyword,
            emphasis_level: kw.emphasis_level,
            selected: kw.selected,
          })),
        },
        patch: {
          entries: patchKWs.map(kw => ({
            keyword: kw.keyword,
            emphasis_level: kw.emphasis_level,
            id: Number(kw.id.toString().replace('new_', '')),
          })),
        },
        del: {
          keyword_ids: delKWs.map(kw => Number(kw.id.toString().replace('deleted_', ''))),
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([AttributesQueryKey.Keywords, pid]);
          toast.success('PDP Keywords saved successfully');
          handleRegenerateOpen();
        },
      }
    );
  };

  const handleFetchKeywords = () => {
    mutateFetchKWs(pid, {
      onSuccess: data => {
        toast.success('Started keywords fetching process');
        setIsCQRSLoading(true);
        setCQRSID(data.commandID);
      },
    });
  };

  const filterConditionalValue = (value: number) => {
    switch (conditionType) {
      case '<':
        return value < conditionValue;
      case '=':
        return value === conditionValue;
      case '>':
        return value > conditionValue;
      default:
        return false;
    }
  };

  useEffect(() => {
    window.clearInterval(cqrsInterval.current);

    if (!cqrsID) return;

    cqrsInterval.current = window.setInterval(() => {
      cqrs(cqrsID, {
        onSuccess: ({ result, error }) => {
          if (error) {
            window.clearInterval(cqrsInterval.current);
            const parsedError = tryJSONParse(error);
            setIsCQRSLoading(false);

            if (Object.keys(parsedError ?? {}).length) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              toast.error((parsedError as any).message);
            }
          }

          if (!result) return;

          toast.success('Keywords fetched successfully');
          window.clearInterval(cqrsInterval.current);
          setIsCQRSLoading(false);
          queryClient.invalidateQueries([AttributesQueryKey.Keywords, pid]);
          queryClient.invalidateQueries([ContentQueryKey.Content, pid]);
        },
      });
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cqrs, cqrsID]);

  useEffect(() => {
    if (!keywordsData) return;
    const kws: Partial<SavedKeyword>[] = [...keywordsData.keywords];
    if (!kws.length)
      kws.push({
        keyword: '',
        id: `new_${nanoid()}`,
        emphasis_level: 1,
        selected: true,
      });
    setKeywords(kws);
  }, [keywordsData]);

  useEffect(() => {
    if (userService.ensureRetailer(RetailerType.Amazon) && kwTimeRange !== timeRange) {
      handleFetchKeywords();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kwTimeRange, timeRange]);

  return (
    <Box sx={styles.container}>
      <Grid container spacing={2}>
        <Grid xs={poolExpanded ? 8 : 12} item>
          <TableContainer sx={styles.keywords}>
            <TableContainerWithLoading round={false} loading={isKeywordsFetching || isCQRSLoading}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow sx={styles.tableHead}>
                    <TableCell>
                      <TableSortLabel
                        active={sort?.field === SortField.Keyword}
                        onClick={handleSortCreation(SortField.Keyword)}
                        direction={sort?.direction}
                        sx={{ fontSize: 'inherit', color: 'inherit' }}
                      >
                        Keyword
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sort?.field === SortField.EmphasisLevel}
                        onClick={handleSortCreation(SortField.EmphasisLevel)}
                        direction={sort?.direction}
                        sx={{ fontSize: 'inherit', color: 'inherit' }}
                      >
                        Emphasis
                      </TableSortLabel>
                    </TableCell>
                    {userService.ensureRetailer(RetailerType.Target) && (
                      <>
                        <TableCell>
                          <TableSortLabel
                            active={sort?.field === SortField.Volume1}
                            onClick={handleSortCreation(SortField.Volume1)}
                            direction={sort?.direction}
                            sx={{ fontSize: 'inherit', color: 'inherit' }}
                          >
                            TargetVol[1m]
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            active={sort?.field === SortField.Volume6}
                            onClick={handleSortCreation(SortField.Volume6)}
                            direction={sort?.direction}
                            sx={{ fontSize: 'inherit', color: 'inherit' }}
                          >
                            TargetVol[6m]
                          </TableSortLabel>
                        </TableCell>
                      </>
                    )}
                    {userService.ensureRetailer(RetailerType.Autozone) && (
                      <TableCell>
                        <TableSortLabel
                          active={sort?.field === SortField.AutozoneVolume}
                          onClick={handleSortCreation(SortField.AutozoneVolume)}
                          direction={sort?.direction}
                          sx={{ fontSize: 'inherit', color: 'inherit' }}
                        >
                          AutozoneVol
                        </TableSortLabel>
                      </TableCell>
                    )}
                    <TableCell>
                      <TableSortLabel
                        active={sort?.field === SortField.SFR}
                        onClick={handleSortCreation(SortField.SFR)}
                        direction={sort?.direction}
                        sx={{ fontSize: 'inherit', color: 'inherit' }}
                      >
                        AmzSFR
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      {userService.ensureRetailer(RetailerType.Amazon) && (
                        <KWsFilter timeRange={timeRange} setTimeRange={setTimeRange} />
                      )}
                      <LightTooltip title="Add Keyword">
                        <IconButton size="small" onClick={() => setNewKWModalOpen(true)}>
                          <AddIcon />
                        </IconButton>
                      </LightTooltip>
                      <LightTooltip title={poolExpanded ? 'Hide Pool' : 'Expand Pool'} placement="right">
                        <IconButton onClick={handlePoolExpanded}>
                          <KeyboardTabIcon fontSize="small" />
                        </IconButton>
                      </LightTooltip>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedKWs
                    .filter(kw => kw.selected)
                    .map(kw => (
                      <TableRow key={kw.id}>
                        <TableCell>
                          <Box sx={styles.keywordWrapper}>
                            <KWInput
                              kws={flattenKWs}
                              kw={kw.keyword}
                              sx={styles.field}
                              onChange={value => {
                                setKeywords(kws =>
                                  kws.map(_kw => {
                                    if (_kw.id === kw.id) return { ..._kw, keyword: value };
                                    return _kw;
                                  })
                                );
                              }}
                              disabled={isSaving || isKeywordsFetching}
                            />
                            <LightTooltip placement="top" title={<Box sx={styles.info}>{getInfo(kw)}</Box>}>
                              <IconButton size="small">
                                <InfoIcon />
                              </IconButton>
                            </LightTooltip>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <ExpandableSlider
                            value={kw.emphasis_level}
                            min={1}
                            max={3}
                            marks={empasisLVLMarks}
                            sx={styles.emphasisSlider}
                            disabled={isSaving || isKeywordsFetching}
                            markSX={styles.emphasis}
                            onChange={(_: Event, value: number | number[]) => {
                              if (Array.isArray(value)) return;
                              setKeywords(kws =>
                                kws.map(_kw => {
                                  if (_kw.id === kw.id) return { ..._kw, emphasis_level: value };
                                  return _kw;
                                })
                              );
                            }}
                          />
                        </TableCell>
                        {userService.ensureRetailer(RetailerType.Target) && (
                          <>
                            <TableCell>
                              {kw.latest_month_volume ? (
                                <HashContainer value={kw.latest_month_volume} disableCopy />
                              ) : (
                                '-'
                              )}
                            </TableCell>
                            <TableCell>
                              {kw.past_six_months_volume ? (
                                <HashContainer value={kw.past_six_months_volume} disableCopy />
                              ) : (
                                '-'
                              )}
                            </TableCell>
                          </>
                        )}
                        {userService.ensureRetailer(RetailerType.Autozone) && (
                          <TableCell>
                            {kw.autozone_volume ? <HashContainer value={kw.autozone_volume} disableCopy /> : '-'}
                          </TableCell>
                        )}
                        <TableCell>{getSfr(kw) ? <HashContainer value={getSfr(kw)} disableCopy /> : '-'}</TableCell>
                        <TableCell>
                          <LightTooltip title="Remove Keyword">
                            <IconButton size="small" onClick={() => deleteKW(kw.id)} disabled={keywords.length === 1}>
                              <DeleteIcon />
                            </IconButton>
                          </LightTooltip>
                          {/* {!kw.id.toString().includes('new') && (
                          <LightTooltip title="Move to Pool">
                            <IconButton size="small" onClick={() => handlePool(kw.id, true)} disabled={isSaving}>
                              <HideSourceIcon />
                            </IconButton>
                          </LightTooltip>
                        )} */}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainerWithLoading>
          </TableContainer>
        </Grid>
        {poolExpanded && (
          <Grid item xs={4}>
            <TableContainerWithLoading loading={isSuggestionsFetching} sx={styles.pool}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow sx={styles.tableHead}>
                    <TableCell sx={styles.poolLabelCol}>Add</TableCell>
                    <TableCell sx={styles.poolLabelCol}>Keyword</TableCell>
                    {userService.ensureRetailer(RetailerType.Target) && (
                      <TableCell sx={styles.poolLabelCol}>Vol</TableCell>
                    )}
                    <TableCell sx={styles.poolLabelCol}>
                      <TableSortLabel
                        active={sort?.field === SortField.SFR}
                        onClick={handleSortCreation(SortField.SFR)}
                        direction={sort?.direction}
                        sx={{ fontSize: 'inherit', color: 'inherit' }}
                      >
                        AmzSFR
                      </TableSortLabel>
                    </TableCell>
                    <TableCell size="small" sx={styles.poolLabelCol}>
                      <KWsSuggestionsFilter
                        onFilterChange={(type, value) => {
                          setConditionType(type);
                          setConditionValue(value);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={userService.ensureRetailer(RetailerType.Target) ? 5 : 4}>
                      <FieldLabelWrapper label="Suggestions Search" sx={styles.fieldLaberWrapper}>
                        <TextField
                          value={search}
                          onChange={handleSearchChange}
                          disabled={isSuggestionsFetching}
                          variant="standard"
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="end">
                                <LightTooltip title="Submit Search">
                                  <IconButton
                                    onClick={() => {
                                      setSubmittedSearch(search);
                                    }}
                                    size="small"
                                  >
                                    <SearchIcon fontSize="small" />
                                  </IconButton>
                                </LightTooltip>
                                <LightTooltip title="Clear Suggestions">
                                  <IconButton
                                    onClick={() => {
                                      setSearch('');
                                      setSubmittedSearch('');
                                    }}
                                    size="small"
                                  >
                                    <ClearIcon fontSize="small" />
                                  </IconButton>
                                </LightTooltip>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FieldLabelWrapper>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {submittedSearch
                    ? suggestionsData?.keywords
                        .filter(
                          kw =>
                            !sortedKWs.some(savedKW => savedKW.keyword === kw.keyword) &&
                            (conditionType && conditionValue ? filterConditionalValue(Number(kw.amazon_sfr)) : true)
                        )
                        .map(kw => (
                          <TableRow key={kw.keyword}>
                            <TableCell>
                              <IconButton
                                size="small"
                                onClick={() => handleSuggestion(kw)}
                                disabled={isAddingSuggestion}
                              >
                                <AddIcon />
                              </IconButton>
                            </TableCell>
                            <TableCell>{kw.keyword}</TableCell>
                            {userService.ensureRetailer(RetailerType.Target) && (
                              <>
                                <TableCell>
                                  {kw.target_latest_month_volume ? (
                                    <HashContainer value={kw.target_latest_month_volume} disableCopy />
                                  ) : (
                                    '-'
                                  )}
                                </TableCell>
                                <TableCell>
                                  {kw.target_past_six_months_volume ? (
                                    <HashContainer value={kw.target_past_six_months_volume} disableCopy />
                                  ) : (
                                    '-'
                                  )}
                                </TableCell>
                              </>
                            )}
                            <TableCell>
                              {kw.amazon_sfr ?? kw.walmart_sfr ? (
                                <HashContainer value={kw.amazon_sfr ?? kw.walmart_sfr} disableCopy />
                              ) : (
                                '-'
                              )}
                            </TableCell>
                            <TableCell size="small" />
                          </TableRow>
                        ))
                    : sortedKWs
                        .filter(
                          kw =>
                            !kw.selected &&
                            (conditionType && conditionValue ? filterConditionalValue(Number(kw.sfr)) : true)
                        )
                        .sort((a, b) => b.relevance_score - a.relevance_score)
                        .map(kw => (
                          <TableRow key={kw.id}>
                            <TableCell>
                              <IconButton size="small" onClick={() => handlePool(kw.id, false)}>
                                <AddIcon />
                              </IconButton>
                            </TableCell>
                            <TableCell>{kw.keyword}</TableCell>
                            {userService.ensureRetailer(RetailerType.Target) && (
                              <TableCell>
                                <Box sx={styles.volumn}>
                                  <Box sx={styles.volWrapper}>
                                    1m:
                                    {kw.latest_month_volume ? (
                                      <HashContainer value={kw.latest_month_volume} disableCopy />
                                    ) : (
                                      '-'
                                    )}
                                  </Box>
                                  <Box sx={styles.volWrapper}>
                                    6m:
                                    {kw.past_six_months_volume ? (
                                      <HashContainer value={kw.past_six_months_volume} disableCopy />
                                    ) : (
                                      '-'
                                    )}
                                  </Box>
                                </Box>
                              </TableCell>
                            )}
                            <TableCell>{kw.sfr ? <HashContainer value={kw.sfr} disableCopy /> : '-'}</TableCell>
                            <TableCell size="small" />
                          </TableRow>
                        ))}
                </TableBody>
              </Table>
            </TableContainerWithLoading>
          </Grid>
        )}
      </Grid>

      <Box sx={styles.btns}>
        <ButtonWithLoading sx={styles.controlBtn} variant="contained" onClick={handleSave} loading={isSaving}>
          <SaveIcon />
          Save Keywords
        </ButtonWithLoading>

        <ButtonWithLoading
          sx={styles.controlBtn}
          variant="contained"
          onClick={handleFetchKeywords}
          loading={isKWFetching || isCQRSLoading}
        >
          <ReplayIcon />
          Refetch Keywords
        </ButtonWithLoading>
      </Box>

      <ApproveModal
        open={regenerateModalOpen}
        onClose={handleRegenerateClose}
        onCancel={handleRegenerateClose}
        onConfirm={() => {
          handleGenerateAI();
          handleRegenerateClose();
        }}
        vocabulary={{
          yesBtn: 'Generate',
          noBtn: 'Cancel',
          description: 'Would you like to re-generate PDP now?',
        }}
      />

      <NewKWModal
        open={newKWModalOpen}
        onClose={() => setNewKWModalOpen(false)}
        kws={flattenKWs}
        onAddKW={kw => {
          addKW(kw);
          setNewKWModalOpen(false);
        }}
      />
    </Box>
  );
}
