import { useQuery } from '@tanstack/react-query';
import { RetailerType, SyndigoRetailers } from 'common/constants/entities';
import { scopesApi } from 'common/services/api/scopes/scopes-api.service';
import {
  CategoriesParentsFilters,
  GetCategoryHistoryFilters,
  GetWalmartHistoryFilters,
  ScopeCategoryStatsFilters,
  ScopesSpreadsFilters,
  ScopesStatsFilters,
  ScopesUsersFilters,
  ScopesWsFilters,
} from 'common/services/api/scopes/scopes-api.types';
import { userService } from 'common/services/user.service';
import { ScopeId } from 'common/types/common';

export enum ScopesQueryKey {
  ScopesList = 'ScopesList',
  ScopesWsList = 'ScopesWsList',
  ScopesStats = 'ScopesStats',
  ScopesSummary = 'ScopesSummary',
  ScopesSpread = 'ScopesSpread',
  ScopesUsers = 'ScopesUsers',
  ScopeCategoryStats = 'ScopeCategoryStats',
  ScopeCategoriesParents = 'ScopeCategoriesParents',
  WalmartScopesList = 'WalmartScopesList',
  CategoryHistory = 'CategoryHistory',
  WalmartHistory = 'WalmartHistory',
  ProductSyncList = 'ProductSyncList',
  ProductsBySyncId = 'ProductsBySyncId',
}

export function useScopesListQuery(args: ScopesWsFilters, enabled = true) {
  const query = useQuery({
    queryKey: [ScopesQueryKey.ScopesList, args],
    enabled: enabled && userService.ensureRetailer(RetailerType.Walmart) ? Boolean(args?.ws_scope) : true,
    staleTime: 1000 * 10,
    queryFn: async () => {
      const apiRetailer =
        userService.ensureRetailers(SyndigoRetailers) || userService.getRetailer() === RetailerType.Autozone
          ? RetailerType.Target
          : (userService.getAPIRetailer() as RetailerType);

      const scopesRetailer = args?.retailer ?? apiRetailer;

      const { data } = userService.ensureRetailer(RetailerType.Walmart)
        ? await scopesApi.getWalmartScopesList(args)
        : await scopesApi.getScopesList(scopesRetailer.toLowerCase(), args);
      return data.scopes;
    },
  });
  return query;
}

export function useWalmartScopesListQuery(args: ScopesWsFilters, enabled = true) {
  const query = useQuery({
    queryKey: [ScopesQueryKey.WalmartScopesList, args],
    enabled,
    staleTime: 1000 * 10,
    queryFn: async () => {
      const { data } = await scopesApi.getWalmartScopesList(args);
      return data.scopes;
    },
  });
  return query;
}

export function useWsScopesListQuery() {
  const query = useQuery({
    queryKey: [ScopesQueryKey.ScopesWsList],
    staleTime: 1000 * 10,
    enabled: userService.ensureRetailer(RetailerType.Walmart),
    queryFn: async () => {
      const { data } = await scopesApi.getWsScopes();
      return data.ws_scopes;
    },
  });
  return query;
}

export function useProductSyncListQuery() {
  const query = useQuery({
    queryKey: [ScopesQueryKey.ProductSyncList],
    staleTime: 1000 * 10,
    enabled: userService.ensureRetailer(RetailerType.Walmart),
    queryFn: async () => {
      const { data } = await scopesApi.getProductSyncList();
      return data.list;
    },
  });
  return query;
}

export function useProductsBySyncIdQuery(syncId: number, open: boolean) {
  const query = useQuery({
    queryKey: [ScopesQueryKey.ProductsBySyncId],
    staleTime: 1000 * 10,
    enabled: userService.ensureRetailer(RetailerType.Walmart) && open,
    queryFn: async () => {
      const { data } = await scopesApi.getProductsBySyncId(syncId);
      return data.products;
    },
  });
  return query;
}

export function useScopesStatsQuery(filters: ScopesStatsFilters) {
  const query = useQuery({
    queryKey: [ScopesQueryKey.ScopesStats, filters],
    staleTime: 1000 * 10,
    enabled: Boolean(filters.scopeID && filters.wsScope),
    queryFn: async () => {
      const { data } = await scopesApi.getScopesStats(filters);
      return data.dailyStats;
    },
  });
  return query;
}

export function useScopesSummaryQuery(id: ScopeId, ws: number) {
  const query = useQuery({
    queryKey: [ScopesQueryKey.ScopesSummary, id, ws],
    enabled: Boolean(id && ws),
    staleTime: 1000 * 10,
    queryFn: async () => {
      const { data } = await scopesApi.getScopesSummary(id, ws);
      return data.summary;
    },
  });
  return query;
}

export function useScopesSpreadQuery(enabled: boolean, args: ScopesSpreadsFilters) {
  const query = useQuery({
    queryKey: [ScopesQueryKey.ScopesSpread, args],
    enabled: Boolean(args?.id && args?.ws && enabled),
    staleTime: 10000 * 10,
    queryFn: async () => {
      const { data } = await scopesApi.getScopesSpread(args);
      return data;
    },
  });
  return query;
}

export function useScopeCategoryStats(args: ScopeCategoryStatsFilters, disabled?: boolean) {
  const query = useQuery({
    queryKey: [ScopesQueryKey.ScopeCategoryStats, disabled, args],
    enabled: Boolean(args?.id && disabled),
    queryFn: async () => {
      const { data } = await scopesApi.getScopeCategoryStats(args);
      return data;
    },
  });
  return query;
}

export function useScopesUsersQuery(args: ScopesUsersFilters) {
  const query = useQuery({
    queryKey: [ScopesQueryKey.ScopesUsers, args],
    enabled: Boolean(args?.id),
    staleTime: 1000 * 10,
    queryFn: async () => {
      const { data } = await scopesApi.getScopesUsers(args);
      return data;
    },
  });
  return query;
}

export function useCategoriesParentsQuery(args: CategoriesParentsFilters) {
  const query = useQuery({
    queryKey: [ScopesQueryKey.ScopeCategoriesParents, args],
    staleTime: 1000 * 10,
    enabled: Boolean(args.scopeID && args.wsScope),
    queryFn: async () => {
      const { data } = await scopesApi.getCategoriesParents(args);
      return data;
    },
  });
  return query;
}

export function useCategoryHistoryQuery(args: GetCategoryHistoryFilters, enabled?: boolean) {
  const query = useQuery({
    queryKey: [ScopesQueryKey.CategoryHistory, args],
    enabled,
    queryFn: async () => {
      const { data } = await scopesApi.getCategoryHistory(args);
      return data;
    },
  });
  return query;
}

export function useWalmartHistoryQuery(args: GetWalmartHistoryFilters, enabled: boolean) {
  const query = useQuery({
    queryKey: [ScopesQueryKey.WalmartHistory, args],
    enabled,
    queryFn: async () => {
      const { data } = await scopesApi.getWalmartCategoryHistory(args);
      return data.products;
    },
  });
  return query;
}
