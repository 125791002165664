import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { NavItem } from 'common/constants/nav';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ReactNode, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { handleActivePath } from 'common/utils';
import { paper } from 'common/constants/colors';
import { useLocation, useNavigate } from 'react-router-dom';
import { NavItems } from 'root/layout/menu/nav-items';
import { theme } from 'common/constants/theme';
import { Styles } from 'common/types/styles';
import { DemoBlurWrapper } from 'common/ui/containers/demo-blur-wrapper';

const ExpandedLC = '__expandednav';

interface StylesProps {
  open: boolean;
  hovered: boolean;
  active: boolean;
  level: number;
  hidden?: boolean;
}

const getStyles = ({ open, hovered, level, active, hidden }: StylesProps): Styles => ({
  container: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    opacity: hidden ? 0 : 1,
    ':before': { display: 'none' },
  },
  summary: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    color: paper,
    paddingLeft: level > 1 ? `${level * 8 + 32}px` : '32px',
  },
  accordionSummary: { p: 0, position: 'relative' },
  title: {
    fontSize: 14,
    fontWeight: 400,
    marginLeft: 2,
    // eslint-disable-next-line no-nested-ternary
    color: hovered || active ? paper : '#A8A9B2',
    transition: 'color 0.15s ease-in-out',
  },
  icon: { color: hovered || active ? paper : '#A8A9B2', transition: 'color 0.15s ease-in-out', width: 16, height: 16 },
  arrow: {
    color: hovered ? paper : '#A8A9B2',
    marginLeft: 'auto',
    marginRight: 2,
    transform: open ? 'rotate(0deg)' : 'rotate(-90deg)',
    transition: 'transform 0.15s ease-in-out, color 0.15s ease-in-out',
  },
  iconTitleContainer: { display: 'flex', alignItems: 'center' },
  customNestedDetails: { padding: '8px 0' },
  activeLine: {
    width: 3,
    height: '100%',
    backgroundColor: theme.palette.primary.light,
    position: 'absolute',
    left: 0,
    top: 0,
  },
});

interface Props {
  config: NavItem;
  level: number;
  nestedExpands?: Array<() => void>;
}

export function NavRowItem({ config, level, nestedExpands }: Props) {
  const navigate = useNavigate();
  const location = useLocation();

  const [expanded, setExpanded] = useState<boolean>(false);
  const [hovered, setHovered] = useState<boolean>(false);

  const active = useMemo(() => {
    return config.path ? handleActivePath(config.path, location) : false;
  }, [config.path, location]);

  const styles = getStyles({
    open: expanded,
    level,
    hovered,
    active,
    hidden: false,
  });

  const handleExpand = (e: SyntheticEvent, expanded: boolean) => {
    if (!config.nested?.length) return;
    setExpanded(expanded);
  };

  const handleExpandOpen = () => {
    setExpanded(true);
  };

  const handleOpen = () => {
    if (config.nested?.length) return;
    navigate(config.path);
  };

  const handleHover = () => {
    setHovered(hovered => !hovered);
  };

  const renderNavItem = (children: ReactNode): ReactNode => {
    return config.demoBlur ? <DemoBlurWrapper>{children}</DemoBlurWrapper> : children;
  };

  useEffect(() => {
    if (config.path) {
      const active = handleActivePath(config.path, location);
      if (!active) return;
      nestedExpands.forEach(expand => expand());
    }

    const expandedCache = localStorage.getItem(ExpandedLC) ?? '';
    const arr = expandedCache.split(';');

    if (arr.includes(btoa(config.title))) {
      handleExpandOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const expandedCache = localStorage.getItem(ExpandedLC) ?? '';
    let arr = expandedCache.split(';');

    if (expanded) {
      arr.push(btoa(config.title));
    } else {
      arr = arr.filter(item => item !== btoa(config.title));
    }

    localStorage.setItem(ExpandedLC, arr.filter(Boolean).join(';'));
  }, [expanded, config.title]);

  return (
    <Accordion
      sx={styles.container}
      onChange={handleExpand}
      onClick={handleOpen}
      expanded={expanded}
      square
      disableGutters
    >
      <AccordionSummary onMouseEnter={handleHover} onMouseLeave={handleHover} sx={styles.accordionSummary}>
        {active && <Box sx={styles.activeLine} />}
        {renderNavItem(
          <Box sx={styles.summary}>
            <config.icon sx={styles.icon} />
            <Typography sx={styles.title}>{config.title}</Typography>
            {Boolean(config.nested?.length) && <KeyboardArrowDownIcon sx={styles.arrow} />}
          </Box>
        )}
      </AccordionSummary>
      {config.nested?.length && (
        <AccordionDetails sx={config.nested ? styles.customNestedDetails : {}}>
          <NavItems level={level + 1} items={config.nested} nestedExpands={[...nestedExpands, handleExpandOpen]} />
        </AccordionDetails>
      )}
    </Accordion>
  );
}
